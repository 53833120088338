<template>
  <div>
    <Header></Header>
    <div class="jsfn1">
      <img class="jsfnA1" src="@/assets/xwzx.png" alt="" />
      <div class="jsfnB1">通知公告</div>
      <div class="jsfnC1">ANNOUNCEMENTS</div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">{{ productCase.title }}</div>
        <div class="bodyTitle1">
          <div style="width: 25%"></div>
          <div class="product1" style="width: 50%">
            {{ productCase.sendTime }}
          </div>
          <div class="more" style="width: 25%">
            <!-- <div class="datetime" style="width:100%">2022-10-23</div> -->
          </div>
        </div>
      </div>
      <div class="bodyContent">
        <div
          style="width: 1200px; margin-left: 30px"
          v-html="
            productCase.content
              .replaceAll('/dev-api', baseURL)
              .replaceAll('/prod-api', baseURL)
          "
          class="ql-editor"
        ></div>

        <!-- <div>
          <img class="cpalb1" src="@/assets/智慧用电.png" alt="" />
          <img class="cpalb1" src="@/assets/智慧用电2.png" alt="" />
        </div>
        <div class="fontA1">
          &emsp;&emsp;该项目主要由组态模块、数据采集模块、展示模块组成。实时采集设备温度数据，设备状态数据，实现设备异常状态报警，便于快速定位异常设备位置、快速处理故障。
        </div>
        <div class="fontA11">
          &emsp;&emsp;该项目实现电力集团公司基层单位项目立项、上级单位审批、回退、会签、审批流程查看等整个项目管理流程。规范了整电力集团公司项目审批流程、节省了项目从立项到结项各个环节的时间。
        </div> -->
      </div>
      <!-- <div class="LastNext">
        <div v-if="nextMap.previous !== null">
          <div class="last" iconClass="" @click="oneL">
            上一案例：{{ nextMap.previous.title }}
          </div>
        </div>
        <div v-if="nextMap.previous === null">
          <div class="last" iconClass="" @click="oneL"></div>
        </div>

        <div class="next" v-if="nextMap.next !== null" @click="oneN">
          下一案例：{{ nextMap.next.title }}
        </div>
      </div> -->
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
import { getNewsNotice } from "../../api/open";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      last: "",
      next: "智慧煤矿",
      baseURL: process.env.VUE_APP_BASE_API,
      id: null,
      productCase: {},
    //   nextMap: {},
    };
  },
  watch: {
    $route(to, from) {
      //监听URL地址栏参数变化
      if (this.$route.query.id) {
        this.id = this.$route.query.id;
        this.getById();
        // this.toNext();
      }
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getById();
    this.toNext();
  },
  methods: {
    getById() {
      getNewsNotice({ id: this.id }).then((res) => {
        this.productCase = res.data;
      });
    },
    // toNext() {
    //   getNext({ id: this.id }).then((res) => {
    //     this.nextMap = res.data;
    //   });
    // },
    // oneL() {
    //   this.$router.push({
    //     path: "productCase1",
    //     query: { id: this.nextMap.previous.id },
    //   });
    // },
    // oneN() {
    //   this.$router.push({
    //     path: "productCase1",
    //     query: { id: this.nextMap.next.id },
    //   });
    // },

    // oneL() {
    //     // this.$router.push({ name: "productCase1" });
    //     this.$message('已经是第一篇了');
    // },
    // oneN() {
    //     this.$router.push({ name: "productCase2" });
    // }
  },
};
</script>

<style scoped>
/deep/ .ql-size-large{
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 40px;
}
/deep/ img{
  width: 100%;
}
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 60px 0 4px 0;
  color: #183f85;
  letter-spacing: 1px;
  text-align: center;
}
.bodyTitle1 {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product1 {
  /* font-size: 24px; */
  color: #bfbfbf;
  letter-spacing: 1px;
  text-align: center;
}
.more {
  display: flex;
}
.datetime {
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
  text-align: end;
}
.bodyContent {
  display: block;
  /* border-bottom: 1px solid #d8d8d8; */
  padding-bottom: 40px;
  margin-top: 40px;
}
.cpalb1 {
  width: 100%;
  margin: 24px 32px;
}
.fontA1 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 0 32px;
  line-height: 40px;
}
.fontA11 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 46px 32px;
  line-height: 40px;
}
.LastNext {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
}
.last {
  cursor: pointer;
}
.next {
  cursor: pointer;
}
.cpal1 {
  position: relative;
}
.cpalA1 {
  width: 100%;
}
.cpalB1 {
  position: absolute;
  top: 157px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.cpalC1 {
  position: absolute;
  top: 240px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  display: flex;
}
.cpalC2 {
  font-size: 50px;
  color: #fff;
  line-height: 50px;
  letter-spacing: 1px;
  margin-right: 32px;
}
.cpalC3 {
  font-size: 40px;
  color: #fff;
  line-height: 50px;
  letter-spacing: 1px;
}
.jsfn1 {
  position: relative;
}
.jsfnA1 {
  width: 100%;
}
.jsfnB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
</style>
